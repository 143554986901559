import ApiClient from '@/configs/ApiClient';
import { GAMP_HEADER } from '@/constants';
import { ICreateCustomerParams, IUpdateCustomerParams } from '@/modules/customer/interface';
import {
  IApprovalOrRejectParams,
  IShopSubjectLogParams,
  IShopSubjectParams,
  IShopSubjectUserParams,
} from '@/modules/job-management/interface';

const GAMP_ORDER_URL = `${process.env.NEXT_PUBLIC_GAMP_GATEWAY_API_URL}/order/admin/api`;

export const ROUTES = {
  GET_LABEL_CONFIG: 'v1/shops/{subject_id}/label-configs',
  GET_PICK_ADDRESS: 'v1/shops/{subject_id}/pick-addresses',
  PARSE_ADDRESS: 'v1/shops/{subject_id}/parse-address',
  GET_ADDRESS_LV: 'v1/shops/{subject_id}/addresses',
  GET_SHOP_PRODUCTS: 'v1/shops/{subject_id}/products/search',
  GET_ALL_SHOP_PRODUCTS: 'v1/shops/{subject_id}/products/list',
};

const api = new ApiClient('/').getInstance();

export const getCustomerConfigInfo = (id: string) =>
  api.get(`${GAMP_ORDER_URL}/${ROUTES.GET_LABEL_CONFIG.replace('{subject_id}', id)}`);

interface IConfigData {
  id: number;
  label: string;
  is_required: boolean;
  value: any;
}

export const updateCustomerConfigInfo = (id: string, data: IConfigData[]) =>
  api.put(`${GAMP_ORDER_URL}/${ROUTES.GET_LABEL_CONFIG.replace('{subject_id}', id)}`, data);

export const getPickAddress = (id: string) =>
  api.get(`${GAMP_ORDER_URL}/${ROUTES.GET_PICK_ADDRESS.replace('{subject_id}', id)}`);

interface IParseAddressParams {
  address: string;
}

export const parseAddress = (id: string, params: IParseAddressParams) =>
  api.get(`${GAMP_ORDER_URL}/${ROUTES.PARSE_ADDRESS.replace('{subject_id}', id)}`, { params });

interface IAddressLvParams {
  parent_id?: string;
  type?: string;
}

export const getAddressLv = (id: string, params: IAddressLvParams) =>
  api.get(`${GAMP_ORDER_URL}/${ROUTES.GET_ADDRESS_LV.replace('{subject_id}', id)}`, { params });

interface IProductParams {
  term: string;
}

export const getShopProducts = (id: string, params: IProductParams) =>
  api.get(`${GAMP_ORDER_URL}/${ROUTES.GET_SHOP_PRODUCTS.replace('{subject_id}', id)}`, { params });

interface IGetAllShopProductsParams {
  page: number;
  limit: number;
}

export const getAllShopProducts = (id: string, params: IGetAllShopProductsParams) =>
  api.get(`${GAMP_ORDER_URL}/${ROUTES.GET_ALL_SHOP_PRODUCTS.replace('{subject_id}', id)}`, {
    params,
  });
const GAM_URL = `${process.env.NEXT_PUBLIC_GAMP_GATEWAY_API_URL}/solution`;

const solutionApi = new ApiClient(GAM_URL, GAMP_HEADER).getInstance();

export const endpoints = {
  SHOPS: '/api/v1/shops',
  SHOP_DETAIL: '/api/v1/shops/details',
  SHOP_PHONE: '/api/v1/shops/view-phone-shop-contacts',
  UPDATE_SHOP: '/api/v1/shops/update',
  APPROVE_ACCOUNT: '/api/v1/gam/shops/approve-account',
  RECEIVE_ACCOUNT: '/api/v1/gam/shops/receive-account',
  SHOP_SUBJECT_LIST: '/api/v1/subjects',
  SHOP_SUBJECT_LOG: '/api/v1/subjects/logs',
  GET_SHOP_SUBJECT_USER: '/api/v1/cs-representatives',
  SHOP_SUBJECT_STATUSTICS: '/api/v1/subjects/statistics',
};

export const getShops = (params = {}) => solutionApi.get(endpoints.SHOPS, { params });

export const createShop = (params: ICreateCustomerParams) => solutionApi.post(endpoints.SHOPS, params);

export const getShopDetail = (params) => solutionApi.get(endpoints.SHOP_DETAIL, { params });

export const viewPhoneShop = (id, username) =>
  solutionApi.get(endpoints.SHOP_PHONE, { params: { id }, headers: { 'x-username': username } });

export const updateShop = (parmas: IUpdateCustomerParams) => solutionApi.post(endpoints.UPDATE_SHOP, parmas);

export const approveOrRejectAccount = (params: IApprovalOrRejectParams) =>
  solutionApi.post(endpoints.APPROVE_ACCOUNT, params);

export const receiveAccount = (subject_id: string | number) =>
  solutionApi.post(`${endpoints.RECEIVE_ACCOUNT}?subject_id=${subject_id}`);

export const getShopSubjectList = (params: IShopSubjectParams) =>
  solutionApi.get(endpoints.SHOP_SUBJECT_LIST, { params });

export const getShopSubjectLogList = (params: IShopSubjectLogParams) =>
  solutionApi.get(endpoints.SHOP_SUBJECT_LOG, { params });

export const getShopSubjectUser = (params: IShopSubjectUserParams) =>
  solutionApi.get(endpoints.GET_SHOP_SUBJECT_USER, { params });

export const getShopSubjectStatisticList = () => solutionApi.get(endpoints.SHOP_SUBJECT_STATUSTICS);
